import { connect } from 'react-redux';
import { goToStep } from '../../reducers/actions';
import Enrollment from './Enrollment';

function mapStateToProps({ progress, membership, wallet }) {
  const { offeringId, partner, stepId } = progress;
  const { declinedMembership, upgraded } = membership;

  return {
    declinedMembership,
    offeringId,
    partner,
    stepId,
    upgraded,
    wallet,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToStep(index) {
      dispatch(goToStep(index));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);
