import { connect } from 'react-redux';
import DeprecationMessage from './DeprecationMessage';

function mapStateToProps({ config }) {
  return {
    payHost: config.payHost,
  };
}

export default connect(mapStateToProps)(DeprecationMessage);
