import { Constraint, FlexGrid, Form, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import isValidBirthdate from '../../../lib/utils/isValidBirthdate';
import isValidLicense from '../../../lib/utils/isValidLicense';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import WalletPropType from '../../prop-types/wallet';
import FormActionButtons from '../FormActionButtons';
import InputWithValidation from '../InputWithValidation';
import StatesDropdown from '../StatesDropdown/StatesDropdown';
import styles from './LicenseForm.module.css';

const cx = classnames.bind(styles);
const BIRTHDATE_ERROR = 'Please enter a valid birth date (mm/dd/yyyy)';
const NUMBER_ERROR = 'Please enter a valid license number';
const INPUT_DATE_REGEX = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

const checkDateValidity = inputDate => inputDate && isValidBirthdate(inputDate);

export default function LicenseForm({ error, isSaving, onSubmit, wallet }) {
  useTracking(ANALYTICS_SCREENS.PAY_ENROLL_IDENTITY);

  const defaultFormProperties = {
    number: '',
    state: '',
    birthdate: '',
  };

  const [formValues, setFormValues] = React.useState({
    ...defaultFormProperties,
    state: wallet.status?.shipping_address?.region || '',
  });

  const [formErrors, setFormErrors] = React.useState(defaultFormProperties);

  const handleSubmit = React.useCallback((e) => {
    let isValid = true;
    const newFormErrors = { ...defaultFormProperties };
    const { birthdate, number, state } = formValues;

    // Sanitize birthdate removing any characters other than digits and symbols / or -
    const sanitizedBirthdate = birthdate.replace(/(^(0-9|\/|-))/g, '');

    // Sanitize license number
    const sanitizedNumber = number.replace(/-/g, '');

    // Validate license information if given
    if (sanitizedNumber && state && !isValidLicense(state, sanitizedNumber)) {
      newFormErrors.number = NUMBER_ERROR;
      isValid = false;
    }

    if (!sanitizedBirthdate || (sanitizedBirthdate && !isValidBirthdate(sanitizedBirthdate))) {
      newFormErrors.birthdate = BIRTHDATE_ERROR;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(newFormErrors);
      e.preventDefault();
    } else {
      setFormErrors(defaultFormProperties);
      onSubmit(e, {
        ...formValues,
        birthdate: sanitizedBirthdate,
        number: sanitizedNumber,
      });
    }
  }, [defaultFormProperties, formValues, onSubmit]);

  const handleFieldChange = React.useCallback((e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  }, [formErrors, formValues]);

  const onBirthdateInputBlur = () => {
    const isValid = checkDateValidity(formValues.birthdate);
    if (!isValid) {
      setFormErrors({ ...formErrors, birthdate: BIRTHDATE_ERROR });
    } else {
      setFormErrors({ ...formErrors, birthdate: '' });
    }
  };

  const handleBirthdateChange = React.useCallback(({ formattedValue }) => {
    if (INPUT_DATE_REGEX.test(formattedValue)) {
      // given date can be properly formatted
      const parsedDate = parse(formattedValue, 'MM/dd/yyyy', new Date());
      const formattedDate = format(parsedDate, 'yyyy-MM-dd');
      setFormValues({
        ...formValues,
        birthdate: formattedDate,
      });
      setFormErrors({
        ...formErrors,
        birthdate: '',
      });
    } else {
      // given date cannot be properly formatted. onBirthdateInputBlur will handle showing error
      setFormValues({
        ...formValues,
        birthdate: formattedValue,
      });
    }
  }, [formErrors, formValues]);

  return (
    <Form aria-label="Driver Identification Form" action="/license" method="post" onSubmit={handleSubmit} loading={isSaving}>
      <Text
        className={cx('securityPurposesCopy')}
        as="p"
      >
        For security purposes, we use your driver&apos;s license to verify your identity using fraud protection services.
      </Text>
      {!!error && (
        <Text as="p" color="orange">
          {error}
        </Text>
      )}
      <FlexGrid className={cx('licenseForm')} container>
        <FlexGrid.Column tablet={6} desktop={4}>
          <InputWithValidation
            name="number"
            label="Driver's license number"
            onChange={handleFieldChange}
            error={formErrors.number}
            value={formValues.number}
            isValid={formValues.number ? !formErrors.number.length : undefined}
            required
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={4} desktop={3}>
          <StatesDropdown
            name="state"
            placeholder="State"
            onChange={handleFieldChange}
            error={formErrors.state}
            value={formValues.state}
            className={cx('statesDropdown', { filled: formValues.state })}
            required
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={7} desktop={5}>
          <NumberFormat
            format="##/##/####"
            label="Birthdate"
            placeholder="mm/dd/yyyy"
            customInput={InputWithValidation}
            onValueChange={handleBirthdateChange}
            onBlur={onBirthdateInputBlur}
            mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
            allowEmptyFormatting
            error={formErrors.birthdate}
            isValid={formValues.birthdate ? !formErrors.birthdate.length : undefined}
          />
        </FlexGrid.Column>
      </FlexGrid>
      <Constraint desktop={10}>
        <FormActionButtons
          canSubmit={!isSaving}
          stepName="License"
        />
      </Constraint>
    </Form>
  );
}

LicenseForm.propTypes = {
  error: PropTypes.string,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func,
  wallet: WalletPropType,
};

LicenseForm.defaultProps = {
  error: undefined,
  isSaving: false,
  onSubmit: () => { },
  wallet: {},
};
