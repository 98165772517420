import { createAction } from 'redux-api-middleware';
import SocialNetworks from '../constants/socialNetworks';

function unfold(o, prefix) {
  for (const [k, v] of Object.entries(o)) {
    if (v && typeof v === 'object') {
      unfold(v, prefix ? `${prefix}.${k}` : k);
    } else {
      o[k] = prefix ? `${prefix}.${v}` : v;
    }
  }
}

export function generateRequest({ body, ...options }) {
  return createAction({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      gbcsrf: window.gbcsrf,
    },
    body: body && JSON.stringify(body),
    ...options,
  });
}

/* https://www.npmjs.com/package/redux-api-middleware#rsaacredentials
 * credentials in request needs to be set to 'same-origin'
 * cookies originated from same domain only will work with this request
 */
export const generateRequestWithCookie = params => generateRequest({
  ...params,
  credentials: 'same-origin',
});

/* https://www.npmjs.com/package/redux-api-middleware#rsaacredentials
 * credentials in request needs to be set to 'include' so that cookies work cross-domain
 */
export const generateRequestWithCrossDomainCookie = params => generateRequest({
  ...params,
  credentials: 'include',
});

export const Action = {
  Auth: {
    SocialAuthPending: 'SocialAuthPending',
    SocialAuthCompleted: 'SocialAuthCompleted',
    SocialAuthFailed: 'SocialAuthFailed',
    SocialStatusReset: 'SocialStatusReset',
    SocialLinkPending: 'SocialLinkPending',
    SocialLinkDone: 'SocialLinkDone',
    SocialLinkFailed: 'SocialLinkFailed',
    LoginMagicLinkFailed: 'LoginMagicLink_FAILED',
    LoginMagicLinkCompleted: 'LoginMagicLink_FULFILLED',
    LoginMagicLinkPending: 'LoginMagicLink_PENDING',
    LoginCodeFailed: 'LoginCode_FAILED',
    LoginCodeCompleted: 'LoginCode_FULFILLED',
    LoginCodePending: 'LoginCode_PENDING',
    LoginPending: 'LoginPending',
    LoginCompleted: 'LoginCompleted',
    LoginFailed: 'LoginFailed',
    SignupPending: 'SignupPending',
    SignupCompleted: 'SignupCompleted',
    SignupFailed: 'SignupFailed',
    SocialConnectMagicLinkPending: 'SocialConnectMagicLinkPending',
    SocialConnectMagicLinkCompleted: 'SocialConnectMagicLinkCompleted',
    SocialConnectMagicLinkFailed: 'SocialConnectMagicLinkFailed',
  },
  Billing: {
    SetOfferingId: 'SetOfferingId',
  },
  Config: {
    FetchIavProviderDetailsPending: 'FetchIavProviderDetailsPending',
    FetchIavProviderDetailsCompleted: 'FetchIavProviderDetailsCompleted',
    FetchIavProviderDetailsFailed: 'FetchIavProviderDetailsFailed',
  },
  Progress: {
    GoToStep: 'GoToStep',
  },
  Membership: {
    UpgradePending: 'UpgradePending',
    UpgradeCompleted: 'UpgradeCompleted',
    UpgradeFailed: 'UpgradeFailed',
  },
  Microdeposits: {
    ConfirmPending: 'ConfirmMicrodeposits_PENDING',
    ConfirmCompleted: 'ConfirmMicrodeposits_FULFILLED',
    ConfirmFailed: 'ConfirmMicrodeposits_FAILED',
  },
  Profile: {
    ProfileUpdatePending: 'ProfileUpdate_PENDING',
    ProfileUpdateCompleted: 'ProfileUpdate_FULFILLED',
    ProfileUpdateFailed: 'ProfileUpdate_FAILED',
  },
  ShippingAddress: {
    UpdatePending: 'ShippingAddressUpdate_PENDING',
    UpdateCompleted: 'ShippingAddressUpdate_FULFILLED',
    UpdateFailed: 'ShippingAddressUpdate_FAILED',
    ValidationPending: 'ShippingAddressValidation_PENDING',
    ValidationCompleted: 'ShippingAddressValidation_FULFILLED',
    ValidationFailed: 'ShippingAddressValidation_FAILED',
    Reset: 'ShippingAddressReset',
  },
  Bank: {
    DetailPending: 'BankDetail_PENDING',
    DetailCompleted: 'BankDetail_FULFILLED',
    DetailFailed: 'BankDetail_FAILED',
    ResetBankError: 'ResetBankError',
    InstrumentCreatePending: 'BankInstrumentCreate_PENDING',
    InstrumentCreateCompleted: 'BankInstrumentCreate_FULFILLED',
    InstrumentCreateFailed: 'BankInstrumentCreate_FAILED',
  },
  License: {
    LicenseSavePending: 'LicenseSavePending',
    LicenseSaveCompleted: 'LicenseSaveCompleted',
    LicenseSaveFailed: 'LicenseSaveFailed',
  },
  Activation: {
    ActivateCardPending: 'ActivateCardPending',
    ActivateCardCompleted: 'ActivateCardCompleted',
    ActivateCardFailed: 'ActivateCardFailed',
  },
  Program: {
    ClearProgram: 'ClearProgram',
    ClearProgramCompleted: 'ClearProgramCompleted',
    ClearProgramFailed: 'ClearProgramFailed',
    SwitchProgram: 'SwitchProgram',
    SwitchProgramCompleted: 'SwitchProgramCompleted',
    SwitchProgramFailed: 'SwitchProgramFailed',
  },
  Referral: {
    ClaimReferral: 'ClaimReferral',
    ClaimReferralComplete: 'ClaimReferralComplete',
    ClaimReferralFailed: 'ClaimReferralFailed',
  },
  Renew: {
    RenewCardPending: 'RenewCardPending',
    RenewCardCompleted: 'RenewCardCompleted',
    RenewCardFailed: 'RenewCardFailed',
  },
  Reship: {
    ReshipCardPending: 'ReshipCardPending',
    ReshipCardCompleted: 'ReshipCardCompleted',
    ReshipCardFailed: 'ReshipCardFailed',
  },
  Ship: {
    ShipCardPending: 'ShipCardPending',
    ShipCardCompleted: 'ShipCardCompleted',
    ShipCardFailed: 'ShipCardFailed',
  },
  Geo: {
    ResolveLocationFromZipCodePending: 'ResolveLocationFromZipCodePending',
    ResolveLocationFromZipCodeCompleted: 'ResolveLocationFromZipCodeCompleted',
    ResolveLocationFromZipCodeFailed: 'ResolveLocationFromZipCodeFailed',
  },
  GasBackRedemption: {
    RedeemPending: 'RedeemPending',
    RedeemCompleted: 'RedeemCompleted',
    RedeemFailed: 'RedeemFailed',
    ResetGasBackRedemption: 'ResetRedeem',
  },
  GasBackTransfer: {
    UpdateGasBackTransferType: 'UpdateGasBackTransferType',
    UpdateGasBackTransferDetails: 'UpdateGasBackTransferDetails',
    TransferPending: 'TransferPending',
    TransferCompleted: 'TransferCompleted',
    TransferFailed: 'TransferFailed',
    ResetGasBackTransfer: 'ResetGasBackTransfer',
  },
  Features: {
    FeaturesPending: 'FeaturesPending',
    FeaturesCompleted: 'FeaturesCompleted',
    FeaturesFailed: 'FeaturesFailed',
  },
};

unfold(Action);

export const goToStep = stepId => ({
  type: Action.Progress.GoToStep,
  payload: stepId,
});

export const validateShippingAddress = (body, analyticsContext) => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/shipping-address/validate',
  body,
  types: [
    Action.ShippingAddress.ValidationPending,
    {
      type: Action.ShippingAddress.ValidationCompleted,
      meta: { analyticsContext },
    },
    Action.ShippingAddress.ValidationFailed,
  ],
});

export const updateShippingAddress = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/shipping-address',
  body,
  types: [
    Action.ShippingAddress.UpdatePending,
    Action.ShippingAddress.UpdateCompleted,
    Action.ShippingAddress.UpdateFailed,
  ],
});

export const resetAddressError = () => ({
  type: Action.ShippingAddress.Reset,
});

export const fetchBankDetailsWithRoutingNumber = routing => generateRequestWithCookie({
  method: 'GET',
  endpoint: `/banks/${routing}`,
  types: [
    Action.Bank.DetailPending,
    Action.Bank.DetailCompleted,
    Action.Bank.DetailFailed,
  ],
});

export const resetBankAccountError = () => ({
  type: Action.Bank.ResetBankError,
});

export const createBankInstrument = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/banks/plaid',
  body,
  types: [
    Action.Bank.InstrumentCreatePending,
    Action.Bank.InstrumentCreateCompleted,
    Action.Bank.InstrumentCreateFailed,
  ],
});

export const createBankInstrumentWithValidiFi = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/banks/validifi',
  body,
  types: [
    Action.Bank.InstrumentCreatePending,
    Action.Bank.InstrumentCreateCompleted,
    Action.Bank.InstrumentCreateFailed,
  ],
});

export const saveLicense = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/license',
  body,
  types: [
    Action.License.LicenseSavePending,
    Action.License.LicenseSaveCompleted,
    Action.License.LicenseSaveFailed,
  ],
});

export const confirmMicrodeposits = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/microdeposits',
  body,
  types: [
    Action.Microdeposits.ConfirmPending,
    Action.Microdeposits.ConfirmCompleted,
    Action.Microdeposits.ConfirmFailed,
  ],
});

export const activateCard = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/activate',
  body,
  types: [
    Action.Activation.ActivateCardPending,
    Action.Activation.ActivateCardCompleted,
    Action.Activation.ActivateCardFailed,
  ],
});

export const shipCard = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/ship',
  body,
  types: [
    Action.Ship.ShipCardPending,
    {
      type: Action.Ship.ShipCardCompleted,
      meta: { offeringId: body.offeringId },
    },
    Action.Ship.ShipCardFailed,
  ],
});

export const loginWithFacebook = (body, identityHost, analyticsContext) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/social/login/facebook`,
  body,
  types: [
    {
      type: Action.Auth.SocialAuthPending,
      meta: { signedRequest: body.signedRequest },
    },
    {
      type: Action.Auth.SocialAuthCompleted,
      meta: { analyticsContext },
    },
    Action.Auth.SocialAuthFailed,
  ],
});

export const loginWithGoogle = (body, identityHost, analyticsContext) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/social/login/google`,
  body,
  types: [
    {
      type: Action.Auth.SocialAuthPending,
      meta: { signedRequest: body.signedRequest },
    },
    {
      type: Action.Auth.SocialAuthCompleted,
      meta: { analyticsContext },
    },
    Action.Auth.SocialAuthFailed,
  ],
});

export const requestMagicLink = (body, identityHost) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/login-link`,
  body,
  types: [
    Action.Auth.LoginMagicLinkPending,
    Action.Auth.LoginMagicLinkCompleted,
    Action.Auth.LoginMagicLinkFailed,
  ],
});

export const loginWithCode = (body, identityHost) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/login-code`,
  body,
  types: [
    Action.Auth.LoginCodePending,
    Action.Auth.LoginCodeCompleted,
    Action.Auth.LoginCodeFailed,
  ],
});

export const loginWithGBAccount = (body, identityHost, analyticsContext) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/login`,
  body,
  types: [
    Action.Auth.LoginPending,
    {
      type: Action.Auth.LoginCompleted,
      meta: { analyticsContext },
    },
    Action.Auth.LoginFailed,
  ],
});

export const signup = (body, identityHost, analyticsContext) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/signup`,
  body,
  types: [
    Action.Auth.SignupPending,
    {
      type: Action.Auth.SignupCompleted,
      meta: { analyticsContext },
    },
    Action.Auth.SignupFailed,
  ],
});

export const requestSocialConnectMagicLink = (body, identityHost) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/social/connect`,
  body,
  types: [
    Action.Auth.SocialConnectMagicLinkPending,
    Action.Auth.SocialConnectMagicLinkCompleted,
    Action.Auth.SocialConnectMagicLinkFailed,
  ],
});

export const linkSocialAccount = ({ socialNetwork, ...body }, identityHost) => generateRequestWithCrossDomainCookie({
  method: 'POST',
  endpoint: `//${identityHost}/social/link/${socialNetwork === SocialNetworks.FACEBOOK ? 'facebook' : 'google'}`,
  body,
  types: [
    Action.Auth.SocialLinkPending,
    Action.Auth.SocialLinkDone,
    Action.Auth.SocialLinkFailed,
  ],
});

export const resetSocialStatus = () => ({
  type: Action.Auth.SocialStatusReset,
});

export const resolveLocationFromZipCode = zipcode => generateRequest({
  endpoint: `/geo/lookup/${zipcode}`,
  types: [
    Action.Geo.ResolveLocationFromZipCodePending,
    Action.Geo.ResolveLocationFromZipCodeCompleted,
    Action.Geo.ResolveLocationFromZipCodeFailed,
  ],
});

export const setOfferingId = offeringId => ({
  type: Action.Billing.SetOfferingId,
  payload: offeringId,
});

export const upgradeMembership = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/upgrade',
  body,
  types: [
    Action.Membership.UpgradePending,
    Action.Membership.UpgradeCompleted,
    Action.Membership.UpgradeFailed,
  ],
});

export const renewCard = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/renew',
  body,
  types: [
    Action.Renew.RenewCardPending,
    Action.Renew.RenewCardCompleted,
    Action.Renew.RenewCardFailed,
  ],
});

export const reshipCard = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/reship',
  body,
  types: [
    Action.Reship.ReshipCardPending,
    Action.Reship.ReshipCardCompleted,
    Action.Reship.ReshipCardFailed,
  ],
});

export const switchToPartnerProgram = () => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/partner',
  types: [
    Action.Program.SwitchProgram,
    Action.Program.SwitchProgramCompleted,
    Action.Program.SwitchProgramFailed,
  ],
});

export const clearPartnerProgram = () => generateRequestWithCookie({
  method: 'DELETE',
  endpoint: '/partner',
  types: [
    Action.Program.ClearProgram,
    Action.Program.ClearProgramCompleted,
    Action.Program.ClearProgramFailed,
  ],
});

export const applyReferral = body => generateRequestWithCookie({
  method: 'POST',
  endpoint: '/referral',
  body,
  types: [
    Action.Referral.ClaimReferral,
    Action.Referral.ClaimReferralComplete,
    Action.Referral.ClaimReferralFailed,
  ],
});

export const setGasBackTransferType = transferType => ({
  type: Action.GasBackTransfer.UpdateGasBackTransferType,
  payload: transferType,
});

export const updateGasBackTransferDetails = details => ({
  type: Action.GasBackTransfer.UpdateGasBackTransferDetails,
  payload: details,
});

export const resetGasBackTransfer = () => ({
  type: Action.GasBackTransfer.ResetGasBackTransfer,
});

export const transferGasBackToGiftCard = body => generateRequest({
  method: 'POST',
  endpoint: '/gasback/transfer/giftcard',
  body,
  types: [
    Action.GasBackTransfer.TransferPending,
    Action.GasBackTransfer.TransferCompleted,
    Action.GasBackTransfer.TransferFailed,
  ],
});

export const transferGasBackToBankAccount = body => generateRequest({
  method: 'POST',
  endpoint: '/gasback/transfer/bank',
  body,
  types: [
    Action.GasBackTransfer.TransferPending,
    Action.GasBackTransfer.TransferCompleted,
    Action.GasBackTransfer.TransferFailed,
  ],
});

export const transferGasBackToCharity = body => generateRequest({
  method: 'POST',
  endpoint: '/gasback/transfer/charity',
  body,
  types: [
    Action.GasBackTransfer.TransferPending,
    Action.GasBackTransfer.TransferCompleted,
    Action.GasBackTransfer.TransferFailed,
  ],
});

export const confirmGasBackToGiftCardTransfer = body => generateRequest({
  method: 'POST',
  endpoint: '/gasback/transfer/giftcard/purchase',
  body,
  types: [
    Action.GasBackTransfer.TransferPending,
    Action.GasBackTransfer.TransferCompleted,
    Action.GasBackTransfer.TransferFailed,
  ],
});

export const redeemCodeForGasBack = body => generateRequest({
  method: 'POST',
  endpoint: '/gasback/redeem',
  body,
  types: [
    Action.GasBackRedemption.RedeemPending,
    Action.GasBackRedemption.RedeemCompleted,
    Action.GasBackRedemption.RedeemFailed,
  ],
});

export const resetGasBackRedemption = () => ({
  type: Action.GasBackRedemption.ResetGasBackRedemption,
});

export const fetchFeatures = body => generateRequest({
  method: 'POST',
  endpoint: '/features',
  body,
  types: [
    Action.Features.FeaturesPending,
    Action.Features.FeaturesCompleted,
    Action.Features.FeaturesFailed,
  ],
});

export const fetchIavProviderDetails = () => generateRequest({
  method: 'GET',
  endpoint: '/banks/iav/details',
  types: [
    Action.Config.FetchIavProviderDetailsPending,
    Action.Config.FetchIavProviderDetailsCompleted,
    Action.Config.FetchIavProviderDetailsFailed,
  ],
});
