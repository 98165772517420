import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './DeprecationMessage.module.css';

const cx = classNames.bind(styles);

export default function DeprecationMessage({ payHost }) {
  return (
    <div className={cx('deprecationMessage')}>
      Important Notice: The Pay with GasBuddy™ fuel card will no longer be supported beginning early 2025
      but you can sign up for our <a href={`//${payHost}`}>NEW Pay with GasBuddy+™ card here</a>
    </div>
  );
}

DeprecationMessage.propTypes = {
  payHost: PropTypes.string,
};

DeprecationMessage.defaultProps = {
  payHost: 'pay.gasbuddy.com',
};
