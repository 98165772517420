import { Container, FlexGrid, Header, Link, Panel } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';
import { version } from '../../../../package.json';
import DeprecationMessage from '../DeprecationMessage';
import Footer from '../Footer';
import styles from './GradientContainer.module.css';

const cx = classnames.bind(styles);

// TODO: This component should eventually remove the need for the Container component in the regular enrollment flow
// Once that is complete, then we will not need the Footer component anymore as it would be unnecessary abstraction
export default function GradientContainer({
  children,
  color,
  extra,
  logo,
  onBackClick,
  onSkipClick,
  sidebar,
  shouldShowDeprecationMessage,
  shouldShowFooter,
  title,
}) {
  const handleBackClick = useCallback((e) => {
    e.preventDefault();
    onBackClick(e);
  }, [onBackClick]);

  const handleSkipClick = useCallback((e) => {
    e.preventDefault();
    onSkipClick(e);
  }, [onSkipClick]);

  return (
    <div className={cx('outerContainer', color, { offset: !!sidebar })}>
      <Container className={cx('gridContainer')}>
        <FlexGrid>
          {!!logo && (
            <FlexGrid.Column
              className={cx('imageContainer')}
              tabletOffset={sidebar ? 3 : 0}
              desktopOffset={sidebar ? 2 : 0}
            >
              {logo}
            </FlexGrid.Column>
          )}
          {!!sidebar && (
            <FlexGrid.Column className={cx('sidebarContainer')} tablet={3} desktop={2}>
              {sidebar}
            </FlexGrid.Column>
          )}
          <FlexGrid.Column tablet={sidebar ? 9 : 12} desktop={sidebar ? 10 : 12}>
            <Panel className={cx('innerContainer')} color="white">
              {shouldShowDeprecationMessage && (
                <DeprecationMessage />
              )}
              {typeof onSkipClick !== 'undefined' && (
                <Link
                  bold
                  className={cx('back')}
                  onClick={handleSkipClick}
                  to="/"
                  uppercase
                >
                  Skip &gt;
                </Link>
              )}
              {typeof onBackClick !== 'undefined' && (
                <Link
                  bold
                  className={cx('back')}
                  onClick={handleBackClick}
                  to="/"
                  uppercase
                >
                  &lt; Back
                </Link>
              )}
              {title && (
                <Header as="h2" className={cx('header')}>{title}</Header>
              )}
              {children}
              {
                shouldShowFooter && (
                  <Footer />
                )
              }
            </Panel>
            <span className={cx('version')}>v{version} &copy; {new Date().getFullYear()} GasBuddy LLC</span>
            {extra && (
              <Fragment>
                {extra}
                <br />
              </Fragment>
            )}
          </FlexGrid.Column>
        </FlexGrid>
      </Container>
    </div>
  );
}

GradientContainer.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['solidBlue', 'blueRedGradient']),
  extra: PropTypes.node,
  logo: PropTypes.node,
  onBackClick: PropTypes.func,
  onSkipClick: PropTypes.func,
  sidebar: PropTypes.node,
  shouldShowDeprecationMessage: PropTypes.bool,
  shouldShowFooter: PropTypes.bool,
  title: PropTypes.string,
};

GradientContainer.defaultProps = {
  children: undefined,
  color: 'solidBlue',
  extra: undefined,
  logo: undefined,
  onBackClick: undefined,
  onSkipClick: undefined,
  sidebar: undefined,
  shouldShowDeprecationMessage: false,
  shouldShowFooter: true,
  title: undefined,
};
