import { Actions, Button, Constraint, Form, Header, Input, Link, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import EnrollmentSteps from '../../constants/enrollmentSteps';
import PayProgramContext from '../../context/payProgram';
import useTracking from '../../hooks/useTracking';
import CheckingAccountSummary from '../CheckingAccountSummary';
import FormActionButtons from '../FormActionButtons';
import LicenseSummary from '../LicenseSummary';
import MembershipBillingSummary from '../MembershipBillingSummary';
import ShippingAddressSummary from '../ShippingAddressSummary';
import TermsOfService from '../TermsOfService';
import styles from './ReviewAndSubmitForm.module.css';
import sharedStyles from '../../styles/shared.module.css';

const cx = classnames.bind({
  ...styles,
  ...sharedStyles,
});

export default function ReviewAndSubmitForm({
  email,
  error,
  goToStep,
  isLoading,
  offeringId,
  onSubmit,
  periodType,
  price,
  stripeToken,
}) {
  const { trackEvent, trackPageView } = useTracking(ANALYTICS_SCREENS.PAY_ENROLL_CONFIRM);
  const payProgram = useContext(PayProgramContext);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [clientError, setClientError] = useState();

  const handleTermsChecked = useCallback((isAccepted) => {
    setAcceptedTerms(isAccepted);
  }, []);

  const handleSubmit = useCallback((e) => {
    trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_CONFIRM_SUBMIT_TAPPED, {
      AcceptedTerms: acceptedTerms,
    });

    if (acceptedTerms) {
      onSubmit(e, { acceptedTerms, offeringId });
    } else {
      e.preventDefault();
      setClientError('You must accept the terms of service');
    }
  }, [acceptedTerms, offeringId, onSubmit, trackEvent]);

  const handleStripeModalOpened = useCallback(() => {
    trackPageView(ANALYTICS_SCREENS.PAY_PREMIUM_PURCHASE_CREDIT_CARD);
  }, [trackPageView]);

  const handleStripeComplete = useCallback((token) => {
    onSubmit(null, { acceptedTerms, offeringId, token });
  }, [acceptedTerms, offeringId, onSubmit]);

  const handleEditClick = useCallback((e, step) => {
    e.preventDefault();
    trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_EDIT_TAPPED, { SectionEdited: step });
    goToStep(step);
  }, [goToStep, trackEvent]);

  const handleEditAddressClick = useCallback((e) => {
    handleEditClick(e, EnrollmentSteps.ShippingAddress);
  }, [handleEditClick]);

  const handleEditLicenseClick = useCallback((e) => {
    handleEditClick(e, EnrollmentSteps.DriversLicense);
  }, [handleEditClick]);

  const handleEditBankClick = useCallback((e) => {
    handleEditClick(e, EnrollmentSteps.BankAccount);
  }, [handleEditClick]);

  const handleEditBillingClick = useCallback((e) => {
    handleEditClick(e, EnrollmentSteps.Billing);
  }, [handleEditClick]);

  let actions;

  if (offeringId && periodType === 'year') {
    actions = (
      <Actions>
        <Actions.Button
          disabled={!acceptedTerms || isLoading}
          fluid
          loading={isLoading}
          primary
          type="submit"
          tablet={5}
          desktop={4}
        >
          Charge Bank Account
        </Actions.Button>
        <Actions.Action tablet={5} desktop={4}>
          <StripeCheckout
            ComponentClass="aside"
            allowRememberMe
            name="GasBuddy Premium"
            description="1 Year Subscription"
            currency="USD"
            email={email}
            amount={parseFloat(price) * 100} // Stripe wants value in cents
            token={handleStripeComplete}
            stripeKey={stripeToken}
            opened={handleStripeModalOpened}
            disabled={!acceptedTerms || isLoading}
          >
            <Button
              disabled={!acceptedTerms || isLoading}
              secondary
              fluid
              loading={isLoading}
              type="button" // Prevent form submission if JS disabled b/c it will charge bank
              className={cx('payWithCreditCard')}
            >
              Pay With Credit Card
            </Button>
          </StripeCheckout>
        </Actions.Action>
      </Actions>
    );
  } else {
    actions = (
      <FormActionButtons
        primaryButtonText={offeringId ? 'Purchase' : 'Submit'}
        canSubmit={!isLoading}
        stepName="Review"
      />
    );
  }

  const description = `Please review the information you provided carefully before completing your Pay with GasBuddy ${offeringId ? payProgram : ''} enrollment.`;

  return (
    <Form
      action="/ship"
      className={cx('reviewForm')}
      loading={isLoading}
      method="post"
      onSubmit={handleSubmit}
    >
      <Input type="hidden" name="offeringId" value={offeringId} />
      <Constraint tablet={7}>
        <Text as="p" className={cx('subtitle')}>
          {description}
        </Text>
        {!!(clientError || error) && (
          <Text as="p" color="orange">
            {clientError || error}
          </Text>
        )}
      </Constraint>
      <section className={cx('summary')}>
        <Header as="h3">Shipping address</Header>
        <ShippingAddressSummary />
        <Link bold fluid onClick={handleEditAddressClick} to="/enroll" uppercase>Edit</Link>
      </section>
      <section className={cx('summary')}>
        <Header as="h3">Driver&apos;s license</Header>
        <LicenseSummary />
        <Link bold fluid onClick={handleEditLicenseClick} to="/enroll" uppercase>Edit</Link>
      </section>
      <section className={cx('summary')}>
        <Header as="h3">Securely link your checking account</Header>
        <CheckingAccountSummary />
        <Link bold fluid onClick={handleEditBankClick} to="/enroll" uppercase>Edit</Link>
      </section>
      {/* offeringId of 0 means they are in a non-free flow, but have chosen the free program at billing step */}
      {offeringId !== undefined && (
        <section className={cx('summary')}>
          <Header as="h3">Billing plan</Header>
          <MembershipBillingSummary />
          <Link bold fluid onClick={handleEditBillingClick} to="/enroll" uppercase>Edit</Link>
        </section>
      )}
      <Constraint className={cx('termsContainer')} tablet={9}>
        <TermsOfService onCheck={handleTermsChecked} />
      </Constraint>
      <Constraint desktop={10} className={cx('buttonsContainer')}>
        {actions}
      </Constraint>
    </Form>
  );
}

ReviewAndSubmitForm.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  goToStep: PropTypes.func,
  isLoading: PropTypes.bool,
  offeringId: PropTypes.number,
  onSubmit: PropTypes.func,
  periodType: PropTypes.oneOf(['month', 'year']),
  price: PropTypes.string,
  stripeToken: PropTypes.string.isRequired,
};

ReviewAndSubmitForm.defaultProps = {
  error: undefined,
  goToStep: () => {},
  isLoading: false,
  offeringId: undefined,
  onSubmit: () => {},
  periodType: undefined,
  price: undefined,
};
