import { Action } from './actions';

const defaultState = {
  instruments: [],
  features: {
    features: [],
  },
  status: {},
};

export default function walletReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case Action.ShippingAddress.UpdateCompleted:
    case Action.License.LicenseSaveCompleted:
    case Action.Ship.ShipCardCompleted:
    case Action.Bank.InstrumentCreateCompleted:
    case Action.Microdeposits.ConfirmCompleted:
    case Action.Activation.ActivateCardCompleted:
    case Action.Membership.UpgradeCompleted:
      return {
        ...state,
        ...action.payload.wallet,
      };

    default:
      return state;
  }
}
