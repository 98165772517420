import { ThemeProvider } from '@gasbuddy/react-components';
import { useAnalytics } from '@gasbuddy/react-hooks';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Route, Switch } from 'react-router-dom';
import { LOCALYTICS_CUSTOM_DIMENSIONS } from '../../constants/analytics';
import PayPrograms from '../../constants/payPrograms';
import AnalyticsContext from '../../context/analytics';
import Application from '../Application';
import GasBackRedeem from '../GasBackRedeem';
import GasBackTransfer from '../GasBackTransfer';
import PrivateRoute from '../PrivateRoute';

// TODO: Once Application uses GradientContainer, the GasBackTransfer route can go back inside Application
export function Router({ accountId, localyticsId, memberName, recaptchaKey }) {
  const analyticsConfig = useMemo(() => (
    typeof window !== 'undefined' && {
      localytics: {
        domain: 'gasbuddy.com',
        key: localyticsId,
      },
    }
  ), [localyticsId]);
  const analytics = useAnalytics(analyticsConfig);

  if (analytics) {
    if (accountId) {
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_ID, accountId);
    }

    if (memberName) {
      analytics.identifyUser({
        id: memberName,
      });
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_NAME, memberName);
    }
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <AnalyticsContext.Provider value={analytics}>
        <ThemeProvider theme="next">
          <Switch>
            <PrivateRoute path="/gasback/redeem" component={GasBackRedeem} />
            <Route path="/gasback/transfer" component={GasBackTransfer} />
            <Route
              path="/plus"
              render={routerProps => (
                <Application {...routerProps} program={PayPrograms.Plus} />
              )}
            />
            <Route
              path="/premium"
              render={routerProps => (
                <Application {...routerProps} program={PayPrograms.Premium} />
              )}
            />
            <Route path="/" component={Application} />
          </Switch>
        </ThemeProvider>
      </AnalyticsContext.Provider>
    </GoogleReCaptchaProvider>
  );
}

Router.propTypes = {
  accountId: PropTypes.number,
  localyticsId: PropTypes.string,
  memberName: PropTypes.string,
  recaptchaKey: PropTypes.string,
};

Router.defaultProps = {
  accountId: undefined,
  localyticsId: undefined,
  memberName: undefined,
  recaptchaKey: undefined,
};
